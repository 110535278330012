<template>
  <div class="flex">
    <div class="search-full-container w-full h-full absolute left-0 top-0">
      <vx-auto-suggest
        ref="navbarSearch"
        :data="navbarSearchAndPinList"
        search_key="title"
        background-overlay
        class="w-full"
        inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Rechercher ..."
        @input="hnd_search_query_update"
        @selected="selected">


        <template v-slot:group="{ group_name }">
          <br/>
          <p class="font-semibold text-primary">{{ group_name }}</p>
        </template>



        <!-- Membres Suggestion -->
        <template v-slot:Membres="{ suggestion }">
          <div class="flex items-end leading-none py-1">
            <span class="w-1/3">{{ suggestion.name }}</span>
            <span class="w-1/3">{{ suggestion.phone }}</span>
            <span class="w-1/3">{{ suggestion.rolep }}</span>
          </div>
        </template>



        <!-- Factures Suggestion -->
        <template v-slot:Factures="{ suggestion }">
          <div class="flex items-end leading-none py-1">
            <span class="w-1/3">{{ suggestion.type }} N°{{ suggestion.name }}</span>
            <span class="w-1/3">{{ suggestion.nMbr }}</span>
            <span class="w-1/3">{{ suggestion.terminal }}</span>
          </div>
        </template>



        <!-- Groupe Suggestion -->
        <template v-slot:Groupes="{ suggestion }">
          <div class="flex items-end leading-none py-1">
            <span class="w-1/3">{{ suggestion.name }}</span>
          </div>
        </template>



        <!-- No Items Slot -->
        <template v-slot:noResult="{ group_name }">
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="mr-4" />
            <span>Aucun résultat.</span>
          </div>
        </template>

      </vx-auto-suggest>
    </div>
  </div>
</template>


<style>
/* Correction saisie recherche et bouton open menu sur petit ecran*/
@media screen and (max-width: 1200px)
{
  .search-full-container .vs-inputx, 
  .search-full-container .icon-search,
  .search-full-container .vs-con-input
  {
    padding-left:50px;
  }
  .search-full-container .input-span-placeholder
  {
    margin-left:50px;
  }
}
</style>


<script>
//-------------------------------------------------------
// Import
//-------------------------------------------------------
import Utils from '@/assets/utils/utils';
import Phone from '@/assets/utils/phone';
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';

import Member from '@/database/models/member';
import Invoice from '@/database/models/invoice';
import Terminal from '@/database/models/terminal';
import Machine from '@/database/models/machine';


//-------------------------------------------------------
// Objet de stockage des recherches
//-------------------------------------------------------
let navfind = {
  Membres:
  {
    key: "find",
    data: []
  },
  Factures:
  {
    key: "find",
    data: []
  },
  Groupes:
  {
    key: "find",
    data: []
  }
};


//-------------------------------------------------------
// VueJS export cors
//-------------------------------------------------------
export default {
  components: {
    VxAutoSuggest
  },
  data() {
    return {
      navbarSearchAndPinList: navfind,
    }
  },
  mounted:function()
	{
    window.callbackRefreshDataSearch();
  },
  methods:
  {
    selected(item)
    {
      item.Membres ?  this.$router.push(item.Membres.url) .catch(() => {}) : null
      item.Factures ? this.$router.push(item.Factures.url).catch(() => {}) : null
      item.Groupes ?  this.$router.push(item.Groupes.url) .catch(() => {}) : null
    },



    hnd_search_query_update(query)
    {
      // Show overlay if any character is entered
      this.$store.commit('TOGGLE_CONTENT_OVERLAY', query ? true : false)
    },
  }
}

let verroue = false;
//-------------------------------------------------------
// Fonction d'actualisation de la recherche du navbar
//-------------------------------------------------------
window.callbackRefreshDataSearch =( verroue_test = true)=>
{
  if(verroue_test)
  if(verroue)
    return;
  verroue=true;

  Member.getAllMemory(function( allMember ){
  Machine.getAllMemory(function( allMach ){
  Invoice.getAllMemory(function( allInv ){
  Terminal.getAllMemory(function( allTerm )
  {

    //Membre
    let tab = [];
    for( const mbr in allMember )
    {
      let buffer = allMember[mbr];
      tab.push({
        find : buffer.first_name+" "+buffer.last_name+' '+Phone.getLocalPhone(buffer.phone).replace(/ /g,'')+' '+Member.getAuthName(buffer),
        name : buffer.first_name+" "+buffer.last_name,
        phone: Phone.getLocalPhone(buffer.phone),
        rolep: Member.getAuthName(buffer)+' / '+Member.getEtatName(buffer),
        url  : '/admin/member/'+buffer.uid,
      });
    }
    navfind.Membres.data = tab;
    //---------------------------------

    tab = [];
    for( const mach in allMach )
    {
      let buffer = allMach[mach];
      tab.push({
        find : buffer.name,
        name : buffer.name,
        url  : '/admin/machine/'+buffer.uid,
      });
    }
    navfind.Groupes.data = tab;

    //---------------------------------
    tab = [];
    for( const inv in allInv )
    {
      let fac = allInv[inv];
      let name_terminal = '?';
      let name_mbr = '?';

      if(fac.terminal)
      if(allTerm[fac.terminal])
        name_terminal = allTerm[fac.terminal].mac;

      if(fac.member)
      if(allMember[fac.member])
        name_mbr = Utils.StrCapital(allMember[fac.member].first_name+' '+allMember[fac.member].last_name);

      tab.push({
        find     : Invoice.getTypeName(fac)+' '+fac.number,
        type     : Invoice.getTypeName(fac),
        name     : fac.number,
        nMbr     : name_mbr,
        terminal : name_terminal,
        url      : '/admin/invoice/'+fac.uid,
      });
    }
    navfind.Factures.data = tab;
    //---------------------------------
    
  });
  });
  });
  });
}
</script>
